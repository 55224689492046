export default function Features(){
    return(
        <div className="px-20">
            <div className="text-4xl mb-8 text-left"> 
                Activate and Deactivate Features, Affects Billing
            </div>
            <div className="flex flex-row gap-8 mb-8">
                <div className="relative top-2"><label class="switch">
                <input type="checkbox"/>
                <span class="slider round"></span>
                </label>
                </div>
                <div>
                    <div className="mb-2 text-left font-bold">Logic Talk</div>
                    <div className="p-2 bg-yellow-100 rounded">A way to find deficiences in Vision</div>
                </div>
            </div>
            <div className="flex flex-row gap-8 mb-8">
                <div className="relative top-2"><label class="switch">
                <input type="checkbox"/>
                <span class="slider round"></span>
                </label>
                </div>
                <div>
                    <div className="mb-2 text-left font-bold">Particular Tell</div>
                    <div className="p-2 bg-yellow-100 rounded">A way to tell suitable ideas for your company</div>
                </div>
            </div>
            <div className="flex flex-row gap-8">
                <div className="relative top-2"><label class="switch">
                <input type="checkbox"/>
                <span class="slider round"></span>
                </label>
                </div>
                <div>
                    <div className="mb-2 text-left font-bold">Free Raise</div>
                    <div className="p-2 bg-yellow-100 rounded">A way to share important ideas which have not been analyzed</div>
                </div>
            </div>
            <div className="italic my-8 text-left">Changes may take upto 1 day to reflect</div>
    
        </div>
    );
    }