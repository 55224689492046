import Notifications from "./notifications";

export default function Billing(){
    return(
        <div className="px-20">
            <div className="mb-8 flex flex-row"> 
                <div  className="text-4xl mb-4 text-left">Billing</div>
            </div>
            <div className="border-b-2 border-purple-200 flex flex-row gap-12 mb-12 text-2xl">
                <div className=" p-2 ">Saved Cards</div>
                <div className=" border-b-8 p-2 border-yellow-400">Notifications</div>
                <div className=" p-2 ">Invoice History</div>
                <div className=" p-2">Table of Charges</div>
            </div>
            <Notifications/>
        </div>
    );
    }