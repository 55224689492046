

export default function Announce(){
    return(
        <div className="px-20 w-full">
            <div className="flex flex-row gap-16"> 
                <div className=" bg-purple-200 px-4 p-2">+ Announcement</div>
                <div className=" bg-purple-300 px-4 p-2">+ Information</div>
            </div>
    
        </div>
    );
    }