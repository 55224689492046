export default function Org(){
    return(
        <div className="flex flex-row px-20">
         
            <div className="w-full">
                <div className="flex flex-row h-10 gap-32 w-full">
                    <div className="flex flex-row gap-8">
                        <div className=" bg-purple-200 px-4 p-2">+ Add Member</div>
                        <div className=" bg-purple-300 px-4 p-2">+ Add Domain</div>
                    </div>
                    <div className="flex flex-row gap-8">
                        <div className="top-1 relative">Auto Verify Users from Domain</div>
                        <div className=" bg-blue-200 px-4 p-2 text-sm">Enable SSO</div>
                        <div className=" bg-blue-200 px-4 p-2 text-sm">Enable SCIM</div>
                    </div>
                </div>
                <div className="text-2xl p-2 mb-8 mt-12 text-left">Domain List</div>
                <div className="h-12"></div>
                <div className="gap-8 flex flex-row my-12 text-2xl border-b-2 border-purple-200">
                    <div className="p-2 border-b-8 border-yellow-400">Member List</div>
                    <div className="p-2">Verification List</div>
                    <div className="p-2">Admin List</div>
                    <div className="p-2">Blocking</div>
                </div>

                <div className="h-30 w-10/12 bg-pink-300"></div>
                
            </div>
    
        </div>
    );
    }