export default function Data(){
    return(
        <div className="px-20">
            <div className="flex flex-row justify-between"> 
                <div className="flex flex-row gap-12">
                <div className="text-4xl mb-8 text-left">Idea List</div>
                <div className="relative top-2 h-9 bg-purple-200 px-2 pb-2 text-sm pt-2">Top 10 ideas</div>
                </div>
                <div className="top-3 relative flex flex-row text-sm top-3 gap-6">
                <div className="px-4 pt-1 border-2 border-black rounded h-8">{"Nov"}</div>
                <div className="px-4 pt-1 border-2 border-black rounded h-8">{"Oct"}</div>
                </div>
                <div className="text-xl top-3 relative  pr-12">⌘</div>
            </div>
            <div className="flex flex-row gap-8">
                <div>Idea List</div>
                <div>#Sections</div>
                <div>#Score</div>
            </div>
    
        </div>
    );
    }