export default function GetStarted(){
    return(
        <div className="px-20 flex flex-row">
            <div className="w-2/3">
            <div className="text-3xl">How to Get Started</div>
            <div> 
            <div className="p-12 my-12 bg-purple-600 text-white">
                <div>Think on how will they prioritize this sooner</div>
                <div className="text-3xl">Tell your employees to use the App</div>
            </div>
            <div className="p-12 my-12 bg-purple-600 text-white">
                <div>Definitely Tell them About</div>
                <div className="text-3xl">Tell them (discount) about extra usage discount</div>
            </div>
            </div>
            </div>

            <div className="w-1/3">
                <div className="border-b-2 border-black mb-4">Events</div>
                <div className="w-full p-4 mb-4">
                    <div>Sarthak joined "Roaters" Team</div>
                </div>
                <div className="w-full p-4 mb-4">
                    <div>Sarthak posted an idea</div>
                    <div className="text-xs">Roaters</div>
                </div>
            </div>
    
        </div>
    );
    }