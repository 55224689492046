import { useState } from "react";
import Learn from "./grouptabs/learn";
import LOGO from "../images/logo.png"
import Decide from "./grouptabs/decide";
import Group from "./grouptabs/group";
import Data from "./grouptabs/data";
import Transition from "./grouptabs/transition";

export default function Dashboard(){

    const [learn,setLearn] = useState(true)
    const [decide,setDecide] = useState(false)
    const [group,setGroup] = useState(false)
    const [data,setData] = useState(false)
    const [billing,setBilling] = useState(false)
    const [transition,setTransition] = useState(false)

    const showPanel=(panelToShow)=>{
        setLearn(false)
        setDecide(false)
        setGroup(false)
        setData(false)
        setBilling(false)
        setTransition(false)
        if(panelToShow=="learn")
        setLearn(true)
        if(panelToShow=="decide")
        setDecide(true)
        if(panelToShow=="group")
        setGroup(true)
        if(panelToShow=="data")
        setData(true)
        if(panelToShow=="billing")
        setBilling(true)
        if(panelToShow=="transition")
        setTransition(true)
    }
return(
    <div className="flex flex-row">
        <div className="w-1/6 flex flex-col bg-gradient-to-r from-pink-400 to-pink-600 h-screen">
        <div className="pb-2 px-10 pt-8 rounded-2xl text-2xl font-bold flex align-center ">
        <img className="-top-2 w-10 h-12 relative" src={LOGO} alt="Cynthia logo"/>
        <a className="relative text-cyan-300 opacity-80 h-9 pr-3  italic" href="/">c<span className="text-yellow-200">y</span>nthia</a>
        </div>

        <div className={`${learn&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("learn")}>
            Learn
        </div>
        <div className={`${decide&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("decide")}>
            Decide
        </div>
        <div className={`${group&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("group")}>
            Group
        </div>
        <div className={`${data&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("data")}>
            Data
        </div>
        <div className={`${billing&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("billing")}>
            Billing
        </div>
        <div className={`${transition&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("transition")}>
            Transition
        </div>
        </div>
        <div className="w-5/6 bg-gradient-to-r from-gray-100 to-gray-300">
            <div className="w-full p-12 flex flex-row gap-12 justify-between">
                <div className="flex flex-row gap-8 text-xs pl-20">
                    <div className="p-1 px-2  h-6">App Links</div>
                    <div className="p-1 px-2 bg-purple-200 h-6 rounded">App Store</div>
                    <div className="p-1 px-2 bg-purple-300 h-6 rounded">Play Store</div>
                </div>
                <div className="relative -top-2 px-4 py-2 bg-purple-300 rounded-xl">Get Help</div>
                <div className="flex flex-row gap-8 pr-20">
                    <div>Logout</div>
                </div>
            </div>
            <div>
            {learn&&<Learn/>}
            {decide&&<Decide/>}
            {group&&<Group/>}
            {data&&<Data/>}
            {transition&&<Transition/>}
            </div>
        </div>

    </div>
);
}