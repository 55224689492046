import LOGO from "../images/logo.png"


export default function Register(){
    return (
        <div className="flex flex-row bg-purple-800 text-white">
            <div className="w-1/2 p-12 pl-32 pt-20 text-left text-black">
                <div className="p-6 bg-purple-100 rounded-xl">
                    <div className="mb-8 text-xl font-bold">Your organization is ready to make this transition</div>
                    <div className="mb-2">✔️ Mobile usage is not a social or corporate un-justification</div>
                    <div className="mb-2">✔️ Social Media has made us habitual to feeds over 2 decades</div>
                    <div className="mb-2">✔️ Software usage is at an all time high</div>
                    <div className="mb-2">✔️ There has been a huge valley of an organizational fit app</div>
                    <div className="mb-2">✔️ Changing times are rapidly increasing new organizational processes</div>
                    <div className="mb-2 italic">This is all you need to be successful</div>
                </div>
                <div className="mt-12 bg-purple-100 p-6 rounded-xl">
                    <div className="mb-8 font-bold text-xl">Implementation</div>
                    <div className="mb-2">Allow the organization to use the app for a good three to six months</div>
                    <div className="mb-2">Get your top ideators to do talks within your organization</div>
                    <div className="mb-2">Invite us for big talk sessions after you have done the above repeatedly</div>
                </div>
            </div>
            <div className="w-1/2 p-10 pt-16 pl-8 text-left">
                <div className="flex flex-row gap-2 my-8">
                    <img className="-top-2 w-10 h-12 relative"src={LOGO} alt="Cynthia logo"/>
                    <div className="relative -top-2">
                        <a className="text-3xl relative text-cyan-300 opacity-80 h-9 pr-3  italic" href="https://cynthia.ion8.top">c<span className="text-yellow-200">y</span>nthia</a>
                    <div className="text-white text-xs">Thoughts become ideas</div>
                    </div>
                    </div>
                <div className="my-4  italic">Get Started by entering few details</div>
                <div className="grid grid-cols-2">
                <div>
                <div className="text-lg font-semibold mb-2">Email ID</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Full Name</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Firm Website</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Title</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Password</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Confirm Password</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                </div>
                <div className="flex flex-row gap-8">
                    <div className="py-2 px-6 bg-yellow-400 text-black">Submit</div>
                </div>
            </div>
        </div>
    );
}