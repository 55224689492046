
export default function Notifications(){
    return(
        <div>
            <div className="flex flex-row gap-20 mb-8"> 
                <div className="font-bold">Notification Email</div>
                <div>{"sarthak.ib@gmail.com"}</div>
                <div className="text-blue-400 underline text-sm">Change</div>
            </div>
            <div className="flex flex-row gap-4">
                    <div><label class="switch">
                    <input type="checkbox"/>
                    <span class="slider round"></span>
                    </label>
                    </div>
                    <div className="relative -top-px">Send Email a Week before Charging</div>
            </div>
            <div className="mt-4 flex flex-row gap-4">
                    <div><label class="switch">
                    <input type="checkbox"/>
                    <span class="slider round"></span>
                    </label>
                    </div>
                    <div className="relative -top-px">Send Email 2 days before Charging</div>
            </div>
    
        </div>
    );
    }