export default function Policies(){
    return(
        <div className="flex flex-row px-20">
         
            <div className="">
                <div className="px-12 flex flex-row justify-between">
                    
                </div>
                <div className="text-4xl mb-8 text-left">Set Policies</div>
                <div className="h-30 w-80 bg-pink-300"></div>
               

                <div className="flex flex-row gap-4 mb-4">
                    <div><label class="switch">
                    <input type="checkbox"/>
                    <span class="slider round"></span>
                    </label>
                    </div>
                    <div>Allow members to participate in Yearly Employee Championship</div>
                </div>

                <div className="flex flex-row gap-4 mb-4">
                    <div><label class="switch">
                    <input type="checkbox"/>
                    <span class="slider round"></span>
                    </label>
                    </div>
                    <div>Allow members to take their ideas when they leave the company</div>
                </div>

                <div className="flex flex-row gap-4">
                    <div><label class="switch">
                    <input type="checkbox"/>
                    <span class="slider round"></span>
                    </label>
                    </div>
                    <div>Allow members to take STORED ideas when they leave the company</div>
                </div>
                <div className="italic my-8 text-left">Changes may take upto 1 day to reflect</div>
                <div className="h-30 w-10/12 bg-pink-300"></div>
                
            </div>
    
        </div>
    );
    }