import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
      legend: {
        display:false,
      },
      title: {
        display: false
      },
    },
  };
  
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  
export const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: labels.map(() => Math.floor(Math.random() * 1001)),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dataset 2',
        data: labels.map(() => Math.floor(Math.random() * 1001)),
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  

export default function Stats(){
    return(
        <div className="px-20">
            <div className="flex flex-row"> 
                <div className='text-4xl'>Stats</div>

        </div>
        <div className='p-8 w-1/2'>
        <div className='text-left mt-12 text-2xl flex flex-row justify-between'>
          <div>Top 10 ideators</div>
          <div className='flex flex-row'>
          <div className="text-lg px-2 py-1 bg-teal-200">Top 20</div>
          <div className="text-lg px-2 py-1 bg-teal-200">Top 50</div>
          </div>
        </div>
        <div className="flex flex-row justify-between">
            <div>Date Picker</div>
            <div className="flex flex-row gap-4">
                <div className="px-2 py-1 bg-teal-200">Week</div>
                <div className="px-2 py-1 bg-teal-600 text-white">Month</div>
                <div className="px-2 py-1 bg-teal-200">Year</div>
            </div>
        </div>
        <Bar options={options} data={data} />;
        </div>
            {/* <div className="mt-12">Most Active Teams</div>
            <div className="flex flex-row space-between">
                <div>Date Picker</div>
                <div className="flex flex-row gap-4">
                    <div className="px-2 py-1 bg-teal-200">Week</div>
                    <div className="px-2 py-1 bg-teal-600 text-white">Month</div>
                    <div className="px-2 py-1 bg-teal-200">Year</div>
                </div>
            </div> */}
        </div>
    );
    }