export default function Transition(){
    return(
        <div>
        <div className="flex flex-row px-20">
         
            <div className="">
                <div className="px-12 flex flex-row justify-between">
                    
                </div>
                <div className="text-4xl mb-8 text-left">Preferences</div>
                <div className="h-30 w-80 bg-pink-300"></div>
               

                <div className="flex flex-row gap-4 mb-4">
                    <div><label class="switch">
                    <input type="checkbox"/>
                    <span class="slider round"></span>
                    </label>
                    </div>
                    <div>Take data or/and ideas to new company account</div>
                </div>
                <div className="px-8 py-3 bg-black text-white mt-6 mb-2 w-72 text-center">Make a company account</div>
                <div className="text-xs">Please note this will delete this management group account</div>
                <div>10% discount for an year if you onboard all your employees to your company account in a month</div>
                
            </div>

            </div>

            <div className="mx-20 mt-6 w-10/12 border-b-4 border-black"></div>
            <div className="text-3xl px-20 mt-8">Calculate your costs</div>
        </div>
    );
    }