export default function Decide(){
    return(
        <div className="px-20">
            <div className="text-2xl">Download the App now with the above links</div>
            <div className="mb-4 text-lg px-4 py-2 bg-teal-300">This is the area for your temporary group to discuss the requirement of this software in your firm</div>
            <textarea type="textarea" className="p-2 w-1/2 h-60 border-2 border-black"/>
            <div className="mt-2 italic text-xs">{"Posting as Sarthak Aggarwal"}</div>
            <button className="w-24 text-center px-4 py-2 my-8 bg-blue-400">Submit</button>
            <div className="text-2xl mt-2">
                Previous Messages
            </div>
            <div>{""}</div>
            <div className="h-40"></div>
        </div>
    )
}