import LOGO from "../images/logo.png"

export default function Login(){
    return (
        <div className=" bg-purple-700 text-white">
        <div className="flex flex-row">
            <div className="w-1/2 p-12 pt-28 pl-48 text-left">
                <div className="flex flex-row gap-2 mb-8 mt-8">
                    <img className="-top-2 w-10 h-12 relative"src={LOGO} alt="Cynthia logo"/>
                    <div className="relative -top-2">
                        <a className="text-3xl relative text-cyan-300 opacity-80 h-9 pr-3  italic" href="https://cynthia.ion8.top">c<span className="text-yellow-200">y</span>nthia</a>
                    <div className="text-white text-xs">Thoughts become ideas</div>
                    </div>
                    </div>
                <div className="text-4xl mb-8 font-bold">Welcome Back!</div>
                <div className="text-xl mb-4">Some Actions you can take</div>
                <div className="mb-2 text-lg">📍 Make Announcements</div>
                <div className="mb-2 text-lg">📍 Add or Remove Members</div>
                <div className="mb-2 text-lg">📍 Add or Remove Features</div>
                <div className="mb-2 text-lg">📍 Check Billing and Payments</div>
                <div className="mb-2 text-lg">📍 View Organization Activity</div>
                <div className="mb-2 text-lg">📍 Download Idea Data</div>
            </div>
            <div className="w-1/2 p-10 pt-36 text-left">
                <div className="flex flex-row gap-8">
                    <a href=""><div className="p-2 px-6 bg-black text-white">Google</div></a>
                    <div className="p-2 px-6 bg-blue-600 text-white">Microsoft</div>
                </div>
                <div className="mt-8 mb-4">or use SSO/SCIM login</div>
                <div className="mb-4 w-72 text-center px-8 py-3 bg-black text-white">Okta Login</div>
                <div className="w-72 text-center px-8 py-3 bg-blue-700 text-white">Azure Login</div>
                <div className="mt-8 mb-4">or do a regular login</div>
                <div className="text-lg font-bold mb-4">Email ID</div>
                <div className="mb-2"><input className="w-1/2 h-10 border-2 border-black p-1 bg-purple-200"></input></div>
                <div className="mb-6 italic text-xs">Verify Email</div>
                    <div className="flex"><a href=""><div className="p-2 px-6 bg-black text-white">Next</div></a></div>
            </div>
        </div>
        <div className="h-60"></div>
        </div>
    );
}