export default function Group (){
    return(
        <div className="px-20">
            <div className="text-2xl">Add Members</div>
            <div className="text-xs">Please inform the members of their access provision</div>
            <div className="flex flex-row my-4 gap-6">
                <div className="w-48 h-12 my-2 bg-teal-100 text-blue-400 p-2 border-2 border-black">email</div>
                <div className="w-48 h-12 my-2 bg-teal-100 text-blue-400 p-2 border-2 border-black">password</div>
                <div className="px-4 py-2 h-8 my-3 text-xs bg-teal-400">Generate Password</div>
            </div>
            <div className="flex flex-row my-4 gap-6">
                <div className="w-48 h-12 my-2 bg-teal-100 text-blue-400 p-2 border-2 border-black">email</div>
                <div className="w-48 h-12 my-2 bg-teal-100 text-blue-400 p-2 border-2 border-black">password</div>
                <div className="text-xs px-4 py-2 h-8 my-3 bg-teal-400">Generate Password</div>
            </div>
            <div className="flex flex-row my-4 gap-6">
                <div className="w-48 h-12 my-2 bg-teal-100 text-blue-400 p-2 border-2 border-black">email</div>
                <div className="w-48 h-12 my-2 bg-teal-100 text-blue-400 p-2 border-2 border-black">password</div>
                <div className="text-xs px-4 py-2 h-8 bg-blue-300 mt-4">Generate Password</div>
            </div>
            <div>
                <div className="text-xs bg-blue-300 w-24 text-center px-2 py-1">Add another</div>
            </div>
        </div>
    )
}