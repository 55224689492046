import LOGO from "../images/logo.png"

export default function GroupRegister(){
    return (
        <div className="bg-purple-600 text-white">
        <div className="sm:flex sm:flex-row ">
            <div className="sm:w-1/2 p-4 sm:p-12 sm:pt-24 text-left sm:pl-32">
                <div className="mb-8 p-8 bg-purple-900 rounded-xl">
                <div className="mb-4 text-3xl font-bold">Temporary Group for Testing Within Leadership</div>
                <div className="mb-2 font-bold">Dual Purpose</div>
                <div className="text-lg">Evaluate the Software for wider adoption</div>
                <div className="text-lg">Getting Acquainted with it for helpful suggestions</div>
                </div>
                <div className="mb-8 p-8 bg-black rounded-xl">
                <div className="mb-4 text-xl font-bold">Especially for CEO’s</div>
                <div>If you are a CEO or equivalent in your firm, please go ahead and make a group for testing within your leadership team</div>
                <div className="italic text-sm">Your account will be banned if you are not a C-Suite executive.</div>
                </div>
                <div className="mt-8 p-8 bg-purple-800 rounded-xl">
                <div className="flex flex-row gap-8 mb-4">
                    <div className="text-xl p-4">Free Trial</div>
                    <div className="text-xl p-4 bg-white text-black rounded-xl">1 month</div>
                </div>
                <div className="text-xs my-2 pl-2">Credit Card Not Required</div>
                <div className="pl-2 mb-4">Free Trial is 1 month after which you will be charged the affordable <a rel="noreferrer"  className="underline decoration-dotted" href="https://cynthia.ion8.top/pricing" target="_blank">pricing</a> we have.</div>
                <div className="pl-2 text-sm">In US, if you have 20 testers, it will cost you around ~$70/month</div>
                <div className="pl-2 text-sm">In India, if you have 20 testers, it will cost you around ~₹750/month</div>
                </div>
            </div>
            <div className="p-4 sm:w-1/2 sm:pl-6 sm:pt-24 text-left ">
                    <div className="flex flex-row gap-2 my-8">
                    <img className="-top-2 w-10 h-12 relative"src={LOGO} alt="Cynthia logo"/>
                    <div className="relative -top-2">
                        <a className="text-3xl relative text-cyan-300 opacity-80 h-9 pr-3  italic" href="https://cynthia.ion8.top">c<span className="text-yellow-200">y</span>nthia</a>
                    <div className="text-white text-xs">Thoughts become ideas</div>
                    </div>
                    </div>
                <div className="text-4xl mb-8 sm:pr-40 sm:pl-0 px-4">Submit your details, account will be activated instantly</div>
                <div className="grid grid-cols-2">
                <div>
                <div className="text-lg font-semibold mb-2">Email ID</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Full Name</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Firm Website</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Title</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Password</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                <div>
                <div className="text-lg font-semibold mb-2">Confirm Password</div>
                <div className="mb-6"><input className="w-3/4 h-10 border-2 text-black border-black p-1 bg-purple-200"></input></div>
                </div>
                </div>
                <div className="flex flex-row gap-8">
                    <div className="py-2 px-6 bg-yellow-400 text-black">Submit</div>
                </div>
            </div>
            
        </div>
        <div className="h-40"></div>
        </div>
    );
}