import { useState } from "react";
import Announce from "./tabs/announce";
import Org from "./tabs/org";
import Ideas from "./tabs/ideas";
import Stats from "./tabs/stats";
import Features from "./tabs/features";
import Billing from "./tabs/billing/billing";
import GetStarted from "./tabs/getstarted";
import Policies from "./tabs/policies";
import LOGO from "../images/logo.png"

export default function Dashboard(){

    const [started,setStarted] = useState(true)
    const [announce,setAnnounce] = useState(false)
    const [organize,setOrganize] = useState(false)
    const [ideas,setIdeas] = useState(false)
    const [stats,setStats] = useState(false)
    const [billing,setBilling] = useState(false)
    const [policy,setPolicy] = useState(false)
    const [features,setFeatures] = useState(false)

    const showPanel=(panelToShow)=>{
        setStarted(false)
        setAnnounce(false)
        setOrganize(false)
        setIdeas(false)
        setStats(false)
        setBilling(false)
        setPolicy(false)
        setFeatures(false)
        if(panelToShow=="started")
        setStarted(true)
        if(panelToShow=="announce")
        setAnnounce(true)
        if(panelToShow=="organize")
        setOrganize(true)
        if(panelToShow=="ideas")
        setIdeas(true)
        if(panelToShow=="stats")
        setStats(true)
        if(panelToShow=="billing")
        setBilling(true)
        if(panelToShow=="policy")
        setPolicy(true)
        if(panelToShow=="features")
        setFeatures(true)
    }
return(
    <div className="flex flex-row">
        <div className="w-1/6 flex flex-col bg-gradient-to-r from-pink-400 to-pink-600 h-screen">
        <div className="pb-2 px-10 pt-8 rounded-2xl text-2xl font-bold flex align-center ">
        <img className="-top-2 w-10 h-12 relative" src={LOGO} alt="Cynthia logo"/>
        <a className="relative text-cyan-300 opacity-80 h-9 pr-3  italic" href="/">c<span className="text-yellow-200">y</span>nthia</a>
        </div>

        <div className={`${started&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("started")}>
            Get Started
        </div>
        <div className={`${announce&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("announce")}>
            Announce
        </div>
        
        <div className={`${organize&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("organize")}>
            Organization
        </div>
        <div className={`${ideas&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("ideas")}>
            Ideas
        </div>
        <div className={`${stats&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("stats")}>
            Stats
        </div>
        <div className={`${features&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("features")}>
            Features
        </div>
        <div className={`${billing&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("billing")}>
            Billing
        </div>
        <div className={`${policy&&"bg-purple-600 text-white"} p-4 m-2 rounded-xl`} onClick={()=>showPanel("policy")}>
            Policies
        </div>
        </div>
        <div className="w-5/6 bg-gradient-to-r from-gray-100 to-gray-300">
            <div className="w-full p-12 flex flex-row gap-12 justify-between">
                <div className="flex flex-row gap-8 text-xs pl-20">
                    <div className="p-1 px-2  h-6">App Links</div>
                    <div className="p-1 px-2 bg-purple-200 h-6 rounded">App Store</div>
                    <div className="p-1 px-2 bg-purple-300 h-6 rounded">Play Store</div>
                </div>
                <div className="p-1 px-4  h-8 rounded  bg-blue-300">Send top 10 weekly ideas to Slack</div>
                <div className="flex flex-row gap-8 pr-20">
                    <div className="text-3xl">🔔</div>
                    <div className="text-3xl">⚙️</div>
                    <div>Logout</div>
                </div>
            </div>
            <div>
            {started&&<GetStarted/>}
            {announce&&<Announce/>}
            {organize&&<Org/>}
            {ideas&&<Ideas/>}
            {stats&&<Stats/>}
            {features&&<Features/>}
            {billing&&<Billing/>}
            {policy&&<Policies/>}
            </div>
        </div>

    </div>
);
}