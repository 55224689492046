import { useState } from "react";
import Circle from "../../images/circle.png"
import Positioning from "../../images/positioning.png"

export default function Learn(){
    const [ulu, setUlu] = useState(1)
    return(
        <div>
       <div className="flex flex-row gap-8 mb-12 px-20">
            <div onClick={()=>setUlu(1)} className={`${ulu==1?"bg-purple-700 rounded-xl text-white":"bg-purple-300 text-black rounded-xl "} px-4 py-2`}>Intro to Company</div>
            <div onClick={()=>setUlu(2)} className={`${ulu==2?"bg-purple-700 rounded-xl text-white":"bg-purple-300 text-black rounded-xl "} px-4 py-2`}>Intro to Product</div>
            <div onClick={()=>setUlu(3)} className={`${ulu==3?"bg-purple-700 rounded-xl text-white":"bg-purple-300 text-black rounded-xl "} px-4 py-2`}>Intro to Features</div>
            <div onClick={()=>setUlu(4)} className={`${ulu==4?"bg-purple-700 rounded-xl text-white":"bg-purple-300 text-black rounded-xl "} px-4 py-2`}>Vision</div>
       </div>
       {ulu==1&&<div className="px-20">
                <div className="text-sm my-2">It’s day 1. We are involving you to achieve your goals.</div>
                <div className="text-3xl my-6">Winning Qualities in Team Members</div>
                <div className="flex flex-row">
                    <div className="text-xl w-1/2">
                        <div className="text-xl">Those we are trying to inculcate</div>
                        <div className="flex flex-row my-4">
                            <div className="w-1/2">
                                <div>A. Empathy</div>
                                <div>B. Tolerance</div>
                                <div>C. Motivation</div>
                                <div>D. Value</div>
                                <div className="my-2"><a className="my-2" href="https://cynthia.ion8.top/groundwork/empathy" target="_blank"><div className="text-sm text-purple-400">Read Here...</div></a></div>
                            </div>
                            <div className="w-1/2">
                                <div>E. Instigation</div>
                                <div>F. Trial</div>
                                <div>G. Summarizing</div>
                                <div>H. Solving</div>
                                <div className="my-2"><a href="https://cynthia.ion8.top/remnants/instigation" target="_blank"><div className="text-sm text-purple-400">Read Here...</div></a></div>
                            </div>
                        </div>
                    </div>
                    <div className="text-3xl pl-20 w-1/2">
                       <div className="text-xl">Our goal</div>
                       <div>Solving for invisible organizational cuts</div>
                    </div>
                </div>
                <div className="flex flex-row my-6">
                    <div className="w-1/2">
                        <div className="text-xl">We have a solution for this</div>
                        <div className="flex w-full h-40"><div className="m-auto px-6 py-3 bg-teal-300 text-3xl">Cynthia</div></div>
                        <div className="my-12 text-lg">With enough work you can set the organization to run on ideas through Cynthia</div>
                        <div className="text-xl mt-4">Our current goal:</div>
                        <div className="text-3xl mb-12">Imagine Management Teams with a Vacant Mind</div>
                        <div className="text-xl">What does Cynthia do?</div>
                        <div className="text-lg">It creates an employee layer above the task layer where</div>
                        <div className="flex flex-row"><div className="w-4">・</div><div className="w-11/12">Employees share ideas</div></div>
                        <div className="flex flex-row"><div className="w-4">・</div><div className="w-11/12">Employees evaluate ideas</div></div>
                        <div className="text-xl">This removes the hierarchical nature of thought flow</div>
                    </div>
                    <div className="w-1/2"> <img src={Positioning} className="w-10/12 m-auto mt-12"/></div>
                </div>
            </div>}
        {ulu==2&&<div className="mx-20 flex flex-row">
       
            <div className="w-1/2">
                <div className="my-8 text-xl">The Theory</div>
                <div className="my-4 bg-purple-200 px-6 p-6 text-left">
                    <div className="mb-1">The first entry point into thinking about the solution is that it manages idea sharing.</div>
                    <div className="text-sm">It's the most visual part.</div>
                </div>
                <div className="my-4 bg-purple-200 px-6 p-6 text-left">
                    <div className="flex flex-row my-2"><div className="w-4">・</div><div className="w-11/12">The second most important part, and the purpose of this app is to produce ideas which help the company.</div></div>
                    <div className="flex flex-row my-2"><div className="w-4">・</div><div className="w-11/12">Though not enshrined, we think people would not try to prioritize ideas which aren't helpful to the company.</div></div>
                    <div className="flex flex-row my-2"><div className="w-4">・</div><div className="w-11/12">More so, try to share an idea yourself, you will find that you have to be quite thoughtful to do so.</div></div>
                    <div className="flex flex-row my-2"><div className="w-4">・</div><div className="w-11/12">We think this leads to organizational transformation as ideas are not mere useless thoughts.</div></div>
                    <div className="flex flex-row my-2"><div className="w-4">・</div><div className="w-11/12">You can now observe the fact that collection of these ideas, creates an idea bank for your company.</div></div>
                    <div className="flex flex-row my-2"><div className="w-4">・</div><div className="w-11/12">It also creates a real time social feed where employees review thoughtful ideas.</div></div>
                </div> 
                <div className="my-4 bg-purple-200 px-6 p-6 text-left">
                    <div className="flex flex-row my-2"><div>The next part of Cynthia is an ability - reviewers (employees, you...) of ideas can mark points on a particular idea's reasoning.</div></div>
                    <div>These are thoughtful ideas and are being understood by reviewers as second validators. Ideas with most points get popular and our shared weekly over email or even on Slack.</div>
                </div>  
                <div className="my-4 bg-purple-200 px-6 p-6 text-left">
                    <div className=" my-2 flex flex-row"><div>Next up are the features of Cynthia, where you can share logical and critical thoughts in the form of ideas. This makes the social feed richer and more friendlier.</div></div>
                    <div className=" my-2">Directly leading to a process where people feel empowered as they can share thoughts with the whole organization if they are clever about doing so.</div>
                    <div className=" my-2">This is not like random thought sharing, but what we are calling conversation management, where clear ideas trump group dynamics and organizational politics.</div>
                </div>  
                <div className="my-4 bg-purple-200 px-6 p-6 text-left">
                    <div>The core ideator can have:</div>
                    <div className=" my-2">1:1 support - This means that anybody in the organization can comment on an ideator's idea which may not have support directly by others.</div>
                    <div className=" my-2">Shiny Ideas - As ideas are anonymously shared, whoever finds the idea possible and useful can support the idea, every idea can shine by engaging different sets of employees.</div>
                    <div className=" my-2">Life Long Learning - Having worked on ideas, we know once you start sharing and thinking about ideas, you only get better at valuing them.</div>
                </div>  
            </div>  
            <div className="w-1/2 flex flex-col gap-12">
                <img src={Circle} className="w-10/12 m-auto mt-12"/>
                <div className="m-auto px-6 pt-4 pb-2 text-left bg-yellow-400 w-10/12  text-xs">
                    <div>The next step is to add members and use the app, input ideas yourself and understand more clearly how Cynthia might provide value to your organization.</div>
                    <div className="my-4 py-1 px-2 bg-yellow-700 w-24 text-white text-center m-auto">Add members</div>
                </div>
            </div>
        </div>}
        {ulu==4&&<div className="px-20">
            <div>
                <div>The simplest reason to start now is that you will have trouble installing the app later while wanting to merge it into your culture</div>
                </div>
            </div>}
        <div className="h-40"></div>
        </div>
    );
    }